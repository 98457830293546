<template>
  <div id="app">
    <div class="main" :style="{ 'padding-bottom': mainPadding, 'zoom': fontSize }">
      <v-idle v-if="userInfoState != undefined" @idle="onIdle" :duration=7200 style="display: none;" />
      <modal-idle></modal-idle>

      <top-line-partial v-if="showLayout"></top-line-partial>
      <header-partial v-if="showLayout"></header-partial>
      <secondary-header-partial v-if="showLayout"></secondary-header-partial>
      <router-view />
      <footer-partial v-if="showLayout"></footer-partial>

      <login-modal></login-modal>


      <!--            <news-single-modal></news-single-modal>-->
      <!-- <div class="chat-sec">
              <div class="chat-content">
                  <div class="chat-btn-box d-none d-lg-block">
                      <a href="#" class="chat-btn">Поставите питање</a>
                  </div>
                  <div class="chat-btn-box-m d-block d-lg-none">
                      <a href="#" class="mob-chat-link">
                          <span class="icon-message-circle"></span>
                      </a>
                  </div>
              </div>
          </div> -->
      <div class="d-none d-lg-block">
        <back-to-top bottom="50px" right="50px">
          <button type="button" class="btn btn-primary btn-to-top">{{ $t('goToTheTop') }}</button>
        </back-to-top>
      </div>

      <div class="d-block d-lg-none">
        <back-to-top bottom="58px" class="left_back-to-top_btn">
          <button type="button" class="btn btn-primary btn-to-top">
            <span class="icon-chevrons-up"></span>
          </button>
        </back-to-top>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import {  mutations } from "@/store";
import { mapActions } from 'vuex'
import LoginModal from './components/LoginModal.vue';
import ModalIdle from './components/ModalIdle.vue';
import HeaderPartial from '@/partials/HeaderPartial.vue';
import TopLinePartial from '@/partials/TopLinePartial.vue';
import FooterPartial from '@/partials/FooterPartial.vue';
import SecondaryHeaderPartial from './partials/SecondaryHeaderPartial.vue';
import { mapState } from 'vuex';
import * as userService from './services/user-service'
import axios from 'axios';
import { env } from "@/env";

export default {
  name: 'home',
  data() {
    return {
      baseApiUrl: env.VUE_APP_API_PENG_DEV,
      mainPadding: '360px !important',
    }
  },
  components: {
    LoginModal,
    HeaderPartial,
    TopLinePartial,
    FooterPartial,
    SecondaryHeaderPartial,
    ModalIdle
  },
  computed: {
    ...mapState(['userInfoState', 'fontSize', 'showLayout', 'showModalIdle']),
  },
  methods: {
    ...mapActions([
      'setUserInfo',
      'resetUserInfo',
      'setShowModalIdle'
    ]),
    updateMainPadding() {
      const list = ['pdf']
      const isChildComponentActive = this.$route.matched.some(
        (route) => list.includes(route.name)
      );

      this.mainPadding = isChildComponentActive ? '0 !important' : '360px !important';
    },
    onIdle() {
      axios.post(this.baseApiUrl + "prins/logout");
      this.setShowModalIdle(true);
      this.$root.$emit('bv::show::modal', 'idle-modal')
    }
  },
  mounted() {
    if (env.CHAT_ENABLE) {
      const scriptLangElement = document.createElement('script');
      if (this.getStoredLanguage() === 'en')
      scriptLangElement.innerHTML = "var lz_data = { overwrite: false, language: 'en' }";
      else scriptLangElement.innerHTML = "var lz_data = { overwrite: false, language: 'srp' };"
      document.body.appendChild(scriptLangElement);

      const chatUrl = env.CHAT_URL;
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.id = env.CHAT_ID;
      scriptElement.defer = true;
      scriptElement.src = chatUrl;

      // Append the script element to the document's head
      document.body.appendChild(scriptElement);
    }

    const self = this;
    axios.post(this.baseApiUrl + 'prins/info')
      .then(function (response) {
        if (response.status === 204) {
          self.resetUserInfo();
        } else {
          let userData = response.data;
          self.setUserInfo(userData);
          userService.setUser(userData);
        }

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  },
  watch: {
    $route() {
      this.updateMainPadding();
    }
  }
}
</script>

<style>
html {
  padding: 0 !important;
}

::v-deep .vbt-table-tools {
  display: none;
}

::v-deep #livezilla_wm {
  top: -25px !important;
}

::v-deep .lz_chat_unselectable,
.lz_overlay_wm_button,
.NotPrintableArea,
.lz_overlay_wm_sh_act,
.lz_anim_slide_in {
  top: -25px !important;
}

::v-deep #lz_overlay_wm .lz_overlay_wm_sh_act {
  top: -25px !important;
}

::v-deep .lz_overlay_wm {
  bottom: 50px !important;
}

::v-deep #lz_overlay_wm {
  margin-bottom: 50px;
}

::v-deep .lz_overlay_wm {
  bottom: 50px !important;
}

::v-deep .at-share-dock.atss {
  position: relative !important;
}

.at-share-dock.atss {
  position: relative !important;
}

::v-deep .atss {
  position: relative !important;
}

/* .atss {
    position: relative!important;
} */
@media(max-width: 800px) {
  #news_modal_17832___BV_modal_title_ {
    font-size: 12px !important;
    text-align: center;
    font-weight: 600;
  }

  .modal-title {
    font-size: 12px !important;
    text-align: center;
    font-weight: 600;
  }

  .mb-2 .mb-md-0 {
    font-size: 12px !important;
    text-align: center;
    font-weight: 600;
  }
}
</style>