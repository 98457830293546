export default {
    en: {
        language: "english",
        content: 'Content',
        newest: 'Newest',
        aboutUs: 'About Us',
        contact: 'Contact',
        search: "Search",
        subscriptionNav: "Subscription",
        LegalActsInEng: 'Legal acts in english',
        group: 'Group',
        issue: 'Issue',
        section: 'Section',
        contents: 'Contents',
        fullTextOfLaw: 'Full text of law',
        changes: 'Changes',
        highlightChanges: 'Highlight changes',
        normalView: 'Normal view',
        show: 'Show',
        fullTextIsAvailableToSubscribersOnly: 'Full text is available to subscribers only',
        forSubscribers: 'For subscribers',
        forNewUsers: 'For new users',
        login: 'Login',
        subscription: 'Subscription',
        user: 'User',
        password: 'Password',
        welcomeToSubscriptionSection: 'Welcome to the subscription section',
        send: 'Send',
        cancel: 'Cancel',
        calculatedVAT: 'calculated VAT {amount}% ',
        subscriberInfo: 'Subscriber info',
        ifSubscripber: 'If you are subscriber please',
        loginSubscriber: 'login',
        name: 'Name',
        surname: 'Surname',
        email: 'E-mail',
        address: 'Address',
        city: 'City',
        zipCode: 'Zip Code',
        country: 'State',
        company: 'Company',
        id: 'ID',
        telFax: 'Tel / Fax',
        company: 'Company',
        www: 'www',
        PIB: 'PIB',
        phone: 'Phone',
        fax: 'Fax',
        userName: 'Username',
        confirmPassword: 'Confirm password',
        mandatoryFields: 'Mandatory fields',
        changeData: 'Change my data',
        youChangedData: 'You have successfully modified your profile information',
        requiredField: 'Field is required',
        passwordMismatch: 'Passwords do not match',
        titleSearch: 'The words contained in the title',
        search: 'Search',
        cancelSearch: 'Cancel search',
        resultSize: 'Result size',
        title: 'Title',
        resultCounterMessage: 'A total of {totalCount} search results were found, the first {limit} are shown. Narrow your search criteria or expand the size of the results.',
        noResultFound: 'No result found',
        allResult: 'A total of {totalCount} search results were found.',
        numberOfTextsInPENG: 'More than 200 basic and refined texts of regulations of the Republic of Serbia translated into English.',
        goToTheTop: "Go to the top of the page",
        askAQuestion: "ASK A QUESTION",
        goThrowSections: "Next/Previous Section",
        maxLength: 'Maximum number of characters is {length} '
    },
    srp: {
        language: 'Srpski',
        content: 'Sadržaj',
        newest: 'Najnovije',
        aboutUs: "O nama",
        contact: "Kontakt",
        search: "Pretraga",
        subscriptionNav: "Pretplata",
        LegalActsInEng: 'Propisi na engleskom jeziku',
        group: 'Grupa',
        issue: 'Izdanje',
        section: 'Sekcija',
        contents: 'Sadržaj',
        fullTextOfLaw: 'Ceo tekst akta',
        changes: 'Izmene',
        highlightChanges: 'Naglasi izmene',
        normalView: 'Bez naglašavanja izmena',
        show: 'Prikaži',
        fullTextIsAvailableToSubscribersOnly: 'Kompletan tekst dostupan je samo pretplatnicima',
        forSubscribers: 'Ako ste pretplatnik',
        forNewUsers: 'Novi korisnik',
        login: 'Prijava',
        subscription: 'Pretplata',
        user: 'Korisnik',
        password: 'Lozinka',
        welcomeToSubscriptionSection: 'Dobrodošli na sekciju za pretplatu',
        send: 'Pošalji',
        cancel: 'Poništi',
        calculatedVAT: 'obračunat PDV {amount}% ',
        subscriberInfo: 'Podaci o naručiocu',
        ifSubscripber: 'Ako ste pretplatnik',
        loginSubscriber: 'prijavite se',
        name: 'Ime',
        surname: 'Prezime',
        email: 'E-mail',
        address: 'Adresa',
        city: 'Grad',
        zipCode: 'Pošt. broj',
        country: 'Država',
        company: 'Naziv firme',
        id: 'PIB/JMBG',
        telFax: 'Tel / Fax',
        company: 'Kompanija',
        www: 'www',
        PIB: 'PIB',
        phone: 'Telefon',
        fax: 'Fax',
        userName: 'Korisničko ime',
        confirmPassword: 'Potvrdite lozinku',
        mandatoryFields: 'Obavezna polja',
        changeData: 'Izmena ličnih podataka',
        youChangedData: 'Uspešno ste izmenili podatke na svom profilu',
        requiredField: 'Polje je obavezno',
        passwordMismatch: 'Lozinke se ne poklapaju',
        titleSearch: 'Reči sadržane u naslovu',
        cancelSearch: 'Poništi pretragu',
        resultSize: 'Veličina rezultata',
        title: 'Naziv',
        resultCounterMessage: 'Nađeno je ukupno {totalCount} rezultata pretrage, prikazano je prvih {limit}. Suzite kriterijum za pretragu ili proširite veličinu rezultata.',
        noResultFound: 'Nije pronađen nijedan rezultat',
        allResult: 'Nađeno je ukupno {totalCount} rezultata pretrage.',
        numberOfTextsInPENG: 'Više od 200 osnovnih i prečišćenih tekstova propisa Republike Srbije prevedenih na engleski jezik.',
        goToTheTop: "Idi na vrh strane",
        askAQuestion: "Postavite pitanje",
        goThrowSections: "Kretanje kroz sekcije",
        maxLength: 'Maksimalan broj karaktera je {length} '
    },
    srpCirilica: {
        language: 'Српски',
        content: 'Садржај',
        newest: 'Најновије',
        aboutUs: "О нама",
        contact: "Контакт",
        search: "Претрага",
        subscriptionNav: "Претплата",
        LegalActsInEng: 'Прописи на енглеском језику',
        group: 'Група',
        issue: 'Издање',
        section: 'Секција',
        contents: 'Садржај',
        fullTextOfLaw: 'Цео текст акта',
        changes: 'Измене',
        highlightChanges: 'Нагласи измене',
        normalView: 'Без наглашавања измена',
        show: 'Прикажи',
        fullTextIsAvailableToSubscribersOnly: 'Комплетан текст доступан је само претплатницима',
        forSubscribers: 'Ако сте претплатник',
        forNewUsers: 'Нови корисник',
        login: 'Пријава',
        subscription: 'Претплата',
        user: 'Корисник',
        password: 'Лозинка',
        welcomeToSubscriptionSection: 'Добродошли на секцију за претплату',
        send: 'Пошаљи',
        cancel: 'Поништи',
        calculatedVAT: 'Обрачунат ПДВ {amount}% ',
        subscriberInfo: 'Подаци о наручиоцу',
        ifSubscripber: 'Ако сте претплатник',
        loginSubscriber: 'пријавите се',
        name: 'Име',
        surname: 'Презиме',
        email: 'E-mail',
        address: 'Адреса',
        city: 'Град',
        zipCode: 'Пошт. број',
        country: 'Држава',
        company: 'Назив фирме',
        id: 'ПИБ/ЈМБГ',
        telFax: 'Тел / Факс',
        company: 'Компанија',
        www: 'www',
        PIB: 'ПИБ',
        phone: 'Телефон',
        fax: 'Факс',
        userName: 'Корисничко име',
        confirmPassword: 'Потврдите лозинку',
        mandatoryFields: 'Обавезна поља',
        changeData: 'Измена личних података',
        youChangedData: 'Успешно сте изменили податке на свом профилу',
        requiredField: 'Поље је обавезно',
        passwordMismatch: 'Лозинке се не поклапају',
        titleSearch: 'Речи садржане у наслову',
        cancelSearch: 'Поништи претрагу',
        resultSize: 'Величина резултата',
        title: 'Назив',
        resultCounterMessage: 'Нађено је укупно {totalCount} резултата претраге, приказано је првих {limit}. Сузите критеријум за претрагу или проширите величину резултата.',
        noResultFound: 'Није пронађен ниједан резултат',
        allResult: 'Нађено је укупно {totalCount} резултата претраге.',
        numberOfTextsInPENG: 'Више од 200 основних и пречишћених текстова прописа Републике Србије преведених на енглески језик.',
        goToTheTop: "Иди на врх стране",
        askAQuestion: "Поставите питање",
        goThrowSections: "Кретање кроз секције",
        maxLength: 'Максималан број карактера је {length} '
    }

};