<template>
    <div class="base-box__accordion-sec">
        <div class="container-fluid  base-container ">
            <div class="centrirano" id="pocetna1">
                <div v-for="(item, index) in productList" :key="index">
                    <fieldset v-for="(item, index) in item.children" :key="index">
                        <legend :id="item.id">{{ item.name }}</legend>
                        <ul class="mktree" id="tree2">
                            <li v-for="(x, index) in item.children" :key="index">
                                <router-link :to="'/content/' + x.id">
                                    <img v-if="userInfoState && x.isSubscribed" src="../assets/img/ok.gif"
                                        alt="Pretplaćeni ste">
                                    <b :class="x.nameStyle">{{ x.name }}</b>
                                    {{ " (" + x.originalName + ")" }}
                                </router-link>
                                <span :class="x.noteStyle">{{ x.note }}</span>
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { env } from '@/env';
import { mapState } from "vuex";

export default {
    name: "Index",
    data: function () {
        return {
            title: "index",
            baseApiUrl: env.VUE_APP_API_PENG_DEV,
            productList: [
                {
                    isSubscribed: true,
                    nameStyle: "",
                    name: "",
                    originalName: "",
                    noteStyle: "",
                    note: "",
                    children: []
                }
            ],
            scrollToId: null,
        }

    },
    computed: {
        ...mapState(["userInfoState"]),
    },
    mounted() {
        this.getProductsForContent();

        this.$root.$on('userLoggedOut', () => {
            this.getProductsForContent();
        });

        this.$root.$on('userLoggedIn', () => {
            this.getProductsForContent();
        })
    },
    methods: {
        async getProductsForContent() {
            await axios.get(this.baseApiUrl + "Prins/GetProductsForContent")
                .then((response) => {
                    this.productList = response.data;
                    this.scrollToId = this.$route.query.id;
                    if (this.scrollToId) {
                        this.$nextTick(() => {
                            this.scrollToItem();
                        });
                    }
                }).catch(error => {
                    console.log(error)
                })
        },
        scrollToItem() {
            if (this.scrollToId) {
                const element = document.getElementById(parseInt(this.scrollToId));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    // Reset scrollToId to avoid scrolling on subsequent updates
                    this.scrollToId = null;
                }
            }
        },
    },
}
</script>

<style scoped>
.centrirano {
    float: none !important;
    margin-bottom: 20px;
    width: 100% !important;
}
</style>
