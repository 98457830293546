if (process.env.NODE_ENV === "development") {
        window.env = {
                "VUE_APP_API_PENG_DEV": "https://peng.dev.ocp.dev.coming.rs/api/",
                "VUE_APP_BASE_URL_PORTAL": "https://prinsportal.stage.ocp.dev.coming.rs/",

                "PENG_BASE_URL": "https://pengportal.dev.ocp.dev.coming.rs/",

                "CHAT_URL": "//chat.stage.ocp.dev.coming.rs/script.php?id=bda5b088b867f1f1468056a8a2188645",
                "CHAT_ID": "bda5b088b867f1f1468056a8a2188645",
                "CHAT_ENABLE": true
        }
}
export const env = { ...process.env, ...window['env'] }
