let user = null;

export function setUser(userData) {
    user = userData;
}

export function getUser() {
    return user;
}

export function isUserLogged() {
    return user != null;
}