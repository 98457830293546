<template>
  <div class="locale-switcher">
    <div class="jezik btn d-block d-lg-inline-block mb-2 mb-lg-0 btn-sm">
      <a class="language" @click="toggleLanguage"><strong>{{ $i18n.locale === 'en' ? 'српски' : 'english'
          }}</strong></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  methods: {
    toggleLanguage() {
      const newLang = this.$i18n.locale === 'en' ? 'srpCirilica' : 'en';
      this.setLanguage(newLang);

      var scriptElements = document.getElementsByTagName('script');
      for (var i = 0; i < scriptElements.length; i++) {
        var script = scriptElements[i];
        if (script.innerHTML.includes("language: 'en'"))
          script.innerHTML = "var lz_data = { overwrite: false, language: 'srp' };"
        else
          script.innerHTML = "var lz_data = { overwrite: false, language: 'en' };"

        location.reload()
      }
    },
  },
}
</script>
<style scoped>
.language :hover {
  color: #e14356;
  text-decoration: none !important;
}
</style>