<template>
    <div>
        <b-modal id="idle-modal" ref="idle-modal" title="Истекла сесија" @hide="redirectionOnClick">
            <form ref="form" @submit.stop.prevent="redirectionOnClick">
                <p>Поштовани, истекла Вам је сесија.</p>
            </form>
            <template v-slot:modal-footer>
                <b-button variant="default" @click="redirectionOnClick">Иди на насловну страну</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { env } from "@/env";
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            baseApiUrl: env.VUE_APP_API_PENG_DEV,
        }
    },
    methods: {
        ...mapActions([
            'setShowModalIdle'
        ]),
        redirectionOnClick() {

            window.location.href = "/";

            this.$refs['idle-modal'].hide();
            this.setShowModalIdle(false);
        },
    }
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>