import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/porudzbenica-online',
    name: 'porudzbenica-online',
    component: () => import('@/views/PorudzbenicaOnline.vue')
  },
  {
    path: '/content/:productId',
    name: 'content',
    component: () => import('@/views/Content.vue')
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/views/Article.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/newest',
    name: 'newest',
    component: () => import('@/views/Newest.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/User.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Search.vue')
  },
  {
    path: '/html/:productId',
    name: 'html',
    component: () => import('@/views/Html.vue')
  },
  {
    path: '/pdf/:productId',
    name: 'pdf',
    component: () => import('@/views/PDFJSViewer.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the route has a saved position, use it
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      // If the route has a hash (e.g., #elementId), scroll to that element
      return { selector: to.hash };
    } else {
      // Default to scrolling to the top
      return { x: 0, y: 0 };
    }
  },
})

export default router
