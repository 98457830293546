<template>
  <div class="site-header NotPrintableArea site-header">
    <div class="site-header__top-line-sec d-none d-md-block">
      <div class="container-fluid header-container">
        <!--                 d-flex justify-content-between-->
        <div class="site-header__top-line align-items-center">
          <div class="top-line__item top-line__left ">
            <a class="d-flex align-items-center mr-4"
              href="javascript:location.href='mailto:'+String.fromCharCode(115,101,114,118,105,115,64,115,108,103,108,97,115,110,105,107,46,99,111,109)+'?'">
              <span class="white-icon icon-mail mr-2"></span>
              servis@slglasnik.com
            </a>

            <a class="mr-5" href="tel:+381113060300">
              <span class="white-icon icon-phone mr-1"></span>+381 11 30 60 300
            </a>
          </div>
          <div class="top-line__item top-line__center d-flex justify-content-center">
            <a class="mr-3" href="https://www.facebook.com/PISPravnoInformacioniSistemRS/?ref=br_rs" target="_blank">
              <span class="white-icon top-line__soc-icon icon-facebook"></span>
            </a>
            <a class="" href="https://www.youtube.com/channel/UCrIiVzd9GhjrmHyTyd0YhZQ" target="_blank">
              <span class="white-icon top-line__soc-icon icon-youtube"></span>
            </a>
          </div>



          <div class="top-line__item top-line__right d-flex align-items-center justify-content-end">
            <!-- font-weight-bold ako treba dodati -->
            <LocaleSwitcher />
            <a class="mr-4" target="_blank" href="/porudzbenica-online"><span
                class="white-icon icon-shopping-cart mr-2"></span>
              Претплата</a>
            <div class="font-changer">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-sm btm-font-changer" @click="changeFontSize('down')">
                  <span class="icon-minus"></span>
                </button>
                <span class="btn btn-sm btn-group-text" @click="changeFontSize('reset')">A</span>
                <button type="button" class="btn btn-sm btm-font-changer" @click="changeFontSize('up')">
                  <span class="icon-plus"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LocaleSwitcher from "../components/LocaleSwitcher.vue"
 
export default {
  name: "TopLinePartial",
  data() {
    return {
      fontSize: 100
    };
  },
  components: {
    LocaleSwitcher
  },
  methods: {
    ...mapActions(["setFontSize"]),
    changeFontSize(action) {
      switch (action) {
        case "down":
          if (this.fontSize >= 85) {
            this.fontSize = this.fontSize - 5;
          }
          break;
        case "up":
          if (this.fontSize <= 115) {
            this.fontSize = this.fontSize + 5;
          }

          break;
        default:
          this.fontSize = 100;
      }
      this.setFontSize(this.fontSize + "%");
    }
  }
};
</script>

<style scoped></style>
