<template>
    <div>
        <b-modal id="login-modal" ref="login_modal" title="Пријава" @hide="prevent" dialog-class="pis-modal">
            <form ref="form" v-on:keyup.enter="handleLogin()" style="display: block !important;">
                <b-form-group>
                    <label for="username-input">Корисничко име</label>
                    <b-form-input placeholder="Корисничко име" id="username-input" v-model="username"
                        required></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="password-input">Лозинка</label>
                    <b-form-input placeholder="Лозинка" id="password-input" v-model="password" type="password"
                        required></b-form-input>
                </b-form-group>
            </form>
            <b-alert variant="danger" :show="errorMessage !== null">{{ errorMessage }}</b-alert>

            <template v-slot:modal-footer class="d-block">
                <div class="btn-group-2-1">
                    <div class="div1">
                        <b-button class="modal-btn cancel-btn btn-block" variant="pis" @click="cancel()">
                            Одустани
                        </b-button>
                    </div>
                    <div class="div2">
                        <b-button class="modal-btn ok-btn btn-block" variant="pis" @click="handleLogin()">
                            Пријава
                        </b-button>
                    </div>
                    <div class="div3" v-if="promotion.length > 0">
                        <router-link class="modal-btn-outline register-btn btn-block" @click.native="closeLogin"
                            to="/promo">Регистрација за ПРОМО период
                        </router-link>
                    </div>

                </div>

            </template>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'vuex'
import { env } from "@/env";
import * as userService from '../services/user-service'

export default {
    name: "LoginModal",
    data() {
        return {
            baseApiUrl: env.VUE_APP_API_PENG_DEV,
            username: null,
            password: null,
            errorMessage: null,
            userType: null,
            promotion: []
        }
    },
    methods: {
        ...mapActions([
            'setUserInfo',
        ]),
        cancel() {
            this.$refs['login_modal'].hide();
            this.errorMessage = null;
        },
        handleLogin() {
            var self = this;
            axios.post(this.baseApiUrl + 'prins/login', {
                password: this.password,
                username: this.username
            })
                .then(response => {
                    self.errorMessage = null;
                    if (response.data.status === -1) {
                        // login nije uspeo
                        self.errorMessage = "Унели сте погрешну лозинку или корисничко име";
                    } else {
                        //console.log("response.data: ", response.data);
                        this.setUserInfo(response.data);
                        this.$root.$emit('userLoggedIn');
                        self.$refs['login_modal'].hide();

                        const vm = self;
                        axios.post(self.baseApiUrl + 'prins/info')
                            .then(function (response) {
                                if (response.status === 204) {
                                    // not logged in;  204
                                } else {
                                    let userData = response.data;
                                    vm.setUserInfo(userData);
                                    userService.setUser(userData);
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                    }
                
 
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.data.status === -1) {
                        // login nije uspeo
                        self.errorMessage = "Унели сте погрешну лозинку или корисничко име";
                    } else {
                        self.errorMessage = "Дошло је до неочекиване грешке";
                    }
                });
    },
    closeLogin: function () {
        this.$refs['login_modal'].hide();
    },
    prevent: function (event) {
        if (this.userType === 1) {
            if (event.trigger === 'backdrop') {
                event.preventDefault();
            } else if (event.trigger === 'headerclose') {
                event.preventDefault();
            }
        }
    }
},
computed: {
        ...mapState([
    'userInfoState'
])
},
mounted() {
    console.log("store.userInfoState: ", this.store);
    this.$root.$on("bv::modal::hide", () => {
        this.errorMessage = null;
    })
}
}
</script>

<style>
.modal-footer {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.75);
}
</style>
