import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueI18n from "vue-i18n";
import messages from "./messages";

import "./assets/styles/main.scss";
import 'jquery-ui/themes/base/all.css';

// bootstrap-vue
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import BackToTop from 'vue-backtotop';
Vue.use(BackToTop);

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'srpCirilica',
  messages,
  numberFormats: {
    srp: {
      currency: {
        style: 'currency',
        currency: 'RSD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'RSD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    }
  }
})

Vue.mixin({
  methods: {
    getStoredLanguage() {
      return localStorage.getItem('language') || 'en';
    },
    setLanguage(lang) {
      localStorage.setItem('language', lang);
      if (this.$i18n)
        this.$i18n.locale = lang;
    },
  },
  created() {
    const storedLanguage = this.getStoredLanguage();
    this.setLanguage(storedLanguage);
  },
});

import axios from 'axios'
axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
)

// https://vuelidate.js.org/
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import Vidle from 'v-idle'
Vue.use(Vidle)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
