import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfoState: null,
    fontSize: '100%',
    showLayout: true,
    showModalIdle: false,
  },
  mutations: {
    setUserInfoMutation(state, data) {
      state.userInfoState = data;
    },
    resetUserInfoMutation(state) {
      state.userInfoState = null;
    },
    setFontSizeMutation(state, data) {
      state.fontSize = data;
    },
    setModalFontSizeMutation(state, data) {
      state.modalFontSize = data;
    },
    setShowLayoutMutation(state, data) {
      state.showLayout = data;
    },
    setShowModalIdleMutation(state, data) {
      state.showModalIdle = data;
    },
  },
  actions: {
    setUserInfo({ commit }, event) {
      commit("setUserInfoMutation", event);
    },
    resetUserInfo({ commit }) {
      commit("setUserInfoMutation");
    },
    setFontSize({ commit }, event) {
      commit('setFontSizeMutation', event);
    },
    setModalFontSize({ commit }, event) {
      commit('setModalFontSizeMutation', event);
    },
    setShowLayout({ commit }, event) {
      commit('setShowLayoutMutation', event);
    },
    setShowModalIdle({ commit }, event) {
      commit('setShowModalIdleMutation', event);
    },
  },
});
