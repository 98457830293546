<template>
    <div class="NotPrintableArea footer_box">
        <div class="site-footer ">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="h-100 footer-col-1 d-flex align-items-start flex-column mb-3 mb-md-0">
                            <a :href="portalUrl" class="footer-logo">
                                <span class="text-pis">
                                    Правно <br>
                                    информациони <br>
                                    систем
                                </span>
                                <span class="text-rs"> Републике Србије </span>
                            </a>
                            <ul class="footer-nav white-links">
                                <li><a :href="portalUrl + 'calendar'">Календар догађаја</a></li>
                                <li><a :href="portalUrl + 'static/termsOfService'">Услови коришћења</a></li>
                                <li><a :href="portalUrl + 'static/impressum'">Импресум</a></li>
                                <li><a :href="portalUrl + 'static/contact'">Контакт</a></li>
                                <li><a href="/porudzbenica-online" target="_blank">Претплата</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="text-md-center SG-logo mb-2 mb-md-0">
                            <a href="http://www.slglasnik.com/" target="_blank" class="d-inline-block">
                                <img src="../assets/img/sluzbeni-glasnik-white-text.png" alt="Glasnik logo">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="footer-col text-md-right mb-2 mb-md-0">
                            <h3 class="footer-col__title">
                                Newsletter
                            </h3>
                            <p class="mb-3">Будите информисани,<br>Пријавите се на newsletter</p>
                            <a :href="portalUrl + 'newsletter'" class="btn footer-btn ">ОДАБЕРИТЕ НОВОСТИ</a>
                            <div class="spacer20"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="site-bottom-sec">
            <div class="container">
                <div class="site-bottom">
                    <div class="row align-items-center">
                        <div class="col-lg-4 order-md-2">
                            <div class="d-md-flex justify-content-between align-items-center">
                                <div class="site-bottom__soc mb-2 mb-lg-0">
                                    <a class="site-bottom__soc-link mr-4" href="https://www.facebook.com/PISPravnoInformacioniSistemRS/?ref=br_rs" target="_blank"><span
                                            class="white-icon icon-facebook"></span></a>
                                    <a class="site-bottom__soc-link mr-0" href="https://www.youtube.com/channel/UCrIiVzd9GhjrmHyTyd0YhZQ" target="_blank"><span
                                            class="white-icon  icon-youtube"></span></a>
                                </div>
                                <div class="footer-browsers d-flex">
                                    <div class="footer-browsers__list mr-2 mb-2 mb-lg-0">
                                        <span class="icon-microsoftedge mr-1" title="microsoft edge"></span>
                                        <span class="icon-googlechrome mr-1" title="Chrome"></span>
                                        <span class="icon-mozillafirefox" title="Firefox"></span>
                                    </div>
                                    Подржани претраживачи
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 order-md-1">
                            <div class="copyright mb-2 mb-lg-0">
                                ©2009 - {{ new Date().getFullYear() }} — Службени гласник РС
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { env } from "@/env";

    export default {
        name: "FooterPartial",
        data() {
            return {
                portalUrl: env.VUE_APP_BASE_URL_PORTAL,
            }
        }

    }
</script>

<style scoped>

</style>
