<template>
    <div style="margin-bottom: 46px;">

        <section class="base-header-sec base-header-lean  lean">
            <div class="base-header__top-line lean"></div>
            <div class="container-fluid">
                <div class=" container-lg  base-header__container ">
                    <div class="inner ml-lg-5 d-flex justify-content-between align-items-center">
                        <ul class="nav ">

                            <li class="nav-item ">
                                <router-link class="nav-link" to="/index" id="tab-pregled">{{ $t('content') }}</router-link>
                            </li>
                            <li class="nav-item ">
                                <router-link class="nav-link" to="/newest" id="tab-najnovije">{{ $t('newest')
                                }}</router-link>
                            </li>
                            <li class="nav-item ">
                                <router-link class="nav-link" to="/search" id="tab-pretraga">{{ $t('search')
                                }}</router-link>
                            </li>
                            <li class="nav-item ">
                                <router-link class="nav-link" to="/porudzbenica-online" id="tab-porudzbenica">{{
                                    $t('subscriptionNav') }}</router-link>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link " href="http://www.slglasnik.com/o-nama" target="_blank"
                                    id="tab-o-nama">
                                    {{ $t('aboutUs') }}</a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link" href="http://www.slglasnik.com/kontakti" target="_blank" id="tab-kontakt">{{
                                    $t('contact') }}</a>
                            </li>
                            <h1 class="base-header__title d-none d-lg-block">
                                {{ $t('LegalActsInEng') }}
                                <span class="as-form-label"> | ISSN 1452-6662</span>
                            </h1>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
        <br />
        <div class="container-fluid base-container">
            <div class="base-box p-small base-box__color-border gold-b" style="padding: 10px;">
                <div class="text-bold text-a1a1a1"> {{ $t('numberOfTextsInPENG') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SecondaryHeaderPartial"
}
</script>
<style scoped>
.info-span {
    font-size: 12px;
    color: #757575;
    font-style: italic;
    margin-left: 5px;
}
</style>